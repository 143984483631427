import "liquid-ajax-cart";

import Alpine from 'alpinejs'
import AlpineCollapse from '@alpinejs/collapse'
import AlpineFocus from '@alpinejs/focus'
import AlpineMorph from '@alpinejs/morph'
import resize from '@alpinejs/resize'
import dialog from '@fylgja/alpinejs-dialog'
import AlpineGlobals from '../js/alpine/index.js'
import helpers, { hasBodyClass } from '../js/helpers.js'
import { register } from 'swiper/element/bundle';

// Dynamic imports
hasBodyClass('product-template') && import('../js/prodify.js')
hasBodyClass('product-template') && import('../js/bis-form.js')

const ns = 'moonshaft'

window.defaultNamespace = ns
window[ns] = (window[ns] || {})
window[ns].helpers = helpers

for (const [key, value] of Object.entries(helpers)) {
  window[ns].helpers[key] = value
}

// register Swiper custom elements
register();

// Register and initialize AlpineJS
window.Alpine = Alpine

Alpine.plugin(
  [
    AlpineCollapse,
    AlpineFocus,
    AlpineMorph,
    resize,
    dialog
  ]
)
AlpineGlobals.register(Alpine)
Alpine.start()

setTimeout(() => {
  const node = document.querySelector('wvomni-overlay');

  const extraSheet = new CSSStyleSheet();
  extraSheet.replaceSync(`
    .powered-by-footer {
      display: none !important;
    }
  `);

  node.shadowRoot.adoptedStyleSheets.push(extraSheet);
}, 1000);
