export default {
  name: 'global',
  store() {
    return {
      isMobileMenuVisible: false,
      isMinicartVisible: false,
      isPredictiveSearchVisible: false,
      isWindowScrolled: false,
      isShowingPhoto: false,
      currentPhotoURL: null,
      cart: null,
      notification: { show: false },
      init() {
        window.addEventListener('scroll', this.onWindowScrollHandler.bind(this))

        this.initLiquidAJaxCart()
      },
      get bodyClasses() {
        let classes = []

        if (this.isMobileMenuVisible) {
          classes.push('mobile-menu-visible')
        }

        if (this.isShowingPhoto) {
          classes.push('overflow-hidden')
        }

        return classes || ''
      },
      openMobileMenu() {
        this.isMobileMenuVisible = true
      },
      closeMobileMenu() {
        this.isMobileMenuVisible = false
      },
      toggleMobileMenu() {
        this.isMobileMenuVisible = !this.isMobileMenuVisible
      },
      showNotification(ok, message, delay = 3000) {
        this.notification = {
          show: true,
          ok: ok,
          message: message
        };

        setTimeout(() => this.notification.show = false, delay);
      },
      showPhoto(url) {
        this.currentPhotoURL = url;
        this.isShowingPhoto = true;

        setTimeout(() => {
          const dialog = document.querySelector("#global-photo-dialog")
          dialog.scrollTo({
            behavior: 'auto',
            top: (2000 - window.innerHeight) / 2,
            left: (2000 - window.innerWidth) / 2
          })
        }, 200)
      },
      closePhoto() {
        this.isShowingPhoto = false;
        setTimeout(() => this.currentPhotoURL = null, 500);
      },
      initLiquidAJaxCart() {
        document.addEventListener("liquid-ajax-cart:request-end", (event) => {
          const { requestState, cart } = event.detail;

          if (requestState.requestType === 'add') {
            if (requestState.responseData?.ok) {
              setTimeout(() => this.isMinicartVisible = true, 50)
            }
          }

          this.cart = cart
        });

        if (window.location.href.includes("/cart")) {
          window.liquidAjaxCart.conf("updateOnWindowFocus", false);

          document.addEventListener("liquid-ajax-cart:request-start", () => {
            this.notification.show = false;
          });

          document.addEventListener("liquid-ajax-cart:request-end", (event) => {
            const { requestState } = event.detail;
            const ok = requestState?.responseData?.ok;

            this.showNotification(ok, ok ? "Cart is updated!" : requestState?.responseData?.body?.message)
          });
        } else {
          // Only handle error
          document.addEventListener("liquid-ajax-cart:request-end", (event) => {
            const { requestState } = event.detail;
            const ok = requestState?.responseData?.ok;

            if (!ok) {
              this.showNotification(false, requestState?.responseData?.body?.message)
            }
          });
        }
      },
      onWindowScrollHandler() {
        const isScrolled = window.scrollY > 0

        this.isWindowScrolled = isScrolled
        document.body.classList[isScrolled ? 'add' : 'remove']('scrolled')
      },
      initSlides(swiperEl) {
        swiperEl.addEventListener('swiperslidechangetransitionend', (event) => {
          const { activeIndex, previousIndex } = event.detail[0];
          const activeSlide = swiperEl.swiper.slides[activeIndex];
          activeSlide.querySelectorAll('video').forEach(v => v.play());

          const prevSlide = swiperEl.swiper.slides[previousIndex];
          prevSlide.querySelectorAll('video').forEach(v => v.pause());
        });
      },
      initProductGallery(swiperEl) {
        swiperEl.addEventListener('swiperslidechange', (event) => {
          const { activeIndex, previousIndex } = event.detail[0];
          const activeSlide = swiperEl.swiper.slides[activeIndex];
          activeSlide.querySelectorAll('.video-button').forEach(v => v.click());

          const prevSlide = swiperEl.swiper.slides[previousIndex];
          prevSlide.querySelectorAll('.video-button.playing').forEach(v => v.click());

          window.scrollTo({ top: 0, behavior: 'smooth' });
        })
      },
      initSwiperProducts(swiperEl) {
        Object.assign(swiperEl, {
          autoplay: {
            enabled: swiperEl.dataset.autoplay,
            pauseOnMouseEnter: true,
            delay: 1
          },
          centeredSlides: true,
          parallax: true,
          speed: 4000,
          freeMode: {
            enabled: true
          },
          spaceBetween: 4,
          slidesPerView: 1.3,
          mousewheel: {
            enabled: true,
            forceToAxis: true
          },
          loop: true,
          breakpoints: {
            640: {
              slidesPerView: 1.3
            },
            768: {
              slidesPerView: 3.3
            },
            1024: {
              slidesPerView: 4.3
            },
            1280: {
              slidesPerView: 5.3
            },
            1536: {
              slidesPerView: 6.3
            }
          }
        });
        swiperEl.initialize();
      },
      scrollToReveal() {
        return {
          lastScrollY: window.scrollY + 0,
          scrollYUp: 0,
          scrollYDown: 0,
          scroll() {
            const scrollY = window.scrollY;
            const height = this.$refs.navbar.offsetHeight;

            let translateY = 0
            if (this.lastScrollY > scrollY) {
              // Scrolling up
              if (this.scrollYUp === 0) {
                this.scrollYUp = scrollY;
              }
              this.scrollYDown = 0

              const scrollDiff = -height + this.scrollYUp - scrollY

              translateY = scrollDiff < 0 && this.scrollYUp > height ? scrollDiff : 0;
            } else {
              // Scrolling down
              if (this.scrollYDown === 0) {
                this.scrollYDown = scrollY
              }
              this.scrollYUp = 0

              translateY = this.scrollYDown - scrollY;
            }

            this.$refs.navbar.style.transform = `translateY(${translateY}px)`
            this.lastScrollY = window.scrollY;
          }
        }
      },
      togglePlayback(el) {
        if (el.tagName === 'VIDEO') {
          if (window.getComputedStyle(el, null).display === 'none') {
            el.pause();
          } else if (el.paused) {
            el.play();
          }
        } else {
          console.warn(el, "should not be using this callback")
        }
      }
    }
  }
}
